<template>
  <div id="app">
    <router-view class="RouterViewCollapsed" />
  </div>
</template>

<script>


export default {
  data() {
    return {
      loggedIn: this.loggedIn,
      user: localStorage.getItem("username"),
    };
  },

  methods: {},
  props: {},
 
};
</script>

<style>

#app{
  overflow-x: hidden;
  overflow-y: hidden;
}


#blockedContent{
  display: none;
}

#appbar {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 7vh;
  background-color: #20262e;
  color: rgb(255, 255, 255);
  box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.55);
  z-index: 2;
  display: flex;
  min-height: 30px;
}

#logo {
  color: #42ba85;
}

.usernameText {
  position: relative;
  top: 7px;
  font-size: 16px;
}

.dropdown-menu {
  background-color: #20262e;
  box-shadow: -2px -4px 15px 2px rgba(0,0,0,0.37);
}

.links:hover {
  background-color: #979797;
}

#settingsIcon {
  margin-left: 30px;
}


.dropdown-item {
  background-color: #20262e;
  color: white;
  cursor:pointer;
}

.dropdown-item:hover {
  background-color: #42ba85;
  color: #2a323d;
}

.title {
  position: fixed;
  font-family: "Arial";
  cursor: pointer;
  font-weight: "bold";
  font-size: 3vh;
  margin-top: 1.5vh;
  margin-left: 20px;
}

#profileIcon {
  background: #20262e;
  border: 0;
  color: white;
  position: relative;

}

.Router-link {
  color: white;
  margin-right: 10%;
  position: relative;
  left: 100%;
  font-size: 20px;
  text-decoration: none;
}

.Router-link:hover {
  color: #42ba85;
}

.RouterView {
  margin-left: 200px;
  margin-top: 7vh;
}

#logoutButton {
  position:fixed;
  float: right;
  right:50px;
  background-color: #20262e;
  display: flex;
  width: 110px;
  border: 0;
  cursor: pointer;
}

#logoutButton:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.btn {
 width: 7vw;
  min-width: 50px;
  float: right;
  
}


.modal-content{
  background-color: #20262e;
  color: white;
}

#pIcon {

  position: relative;
  top: 10px;
  font-size: 20px;
}

#logoutButton:hover {
  color: #42ba85;
}

textarea:focus, input:focus{
    outline: none;
}
/*------------------NAV BUTTONS--------------*/
#navButtonHome{
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 20vw;
  margin-right: 10px;
}

#navButtonHome:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonHome:hover {
  background-color: #42ba85;
  color: black;
}

#navButtonHomeUsers{
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 20vw;
  margin-right: 10px;
}

#navButtonHomeUsers:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonHome:hover {
  background-color: #42ba85;
  color: black;
}

.modal-content{
  box-shadow: -2px -4px 15px 2px rgba(0,0,0,0.37);
}

#navButtonDomains{
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 20vw;
  margin-right: 10px;
}

#navButtonDomains:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDomains:hover {
  background-color: #42ba85;
  color: black;
}

#navButtonDevices{
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 20vw;
  margin-right: 10px;
}

#navButtonDevices:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDevices:hover {
  background-color: #42ba85;
  color: black;
}

#navButtonUsers{
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 20vw;
  margin-right: 10px;
}

#navButtonUsers:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonUsers:hover {
  background-color: #42ba85;
  color: black;
}

#navButtonActive {
  background-color: #42ba85;
  border: 0;
  color: black;
  position: relative;
  left: 20vw;
  margin-right: 10px;
  min-width: 65px;
}

#navButtonActive:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonActive:hover {
  background-color: #42ba85;
  color: black;
}




</style>
